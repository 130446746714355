import React from 'react';

import './styles.scss';

import LogoFooterImg from '../../assets/img/footer.png';

const FooterParticipant = () => {
  return (
    <footer className="footerParticipantMain">
        <div className="container">
            <div className="footerWrapper">
                <div className="footerTextWrapper">
                    <strong>Dúvidas?</strong> Entre em contato com seu Gerente de Relacionamento BV
                </div>
                <div className="footerImgWrapper">
                    <img src={LogoFooterImg} alt="BV" className="footerLogo" />
                </div>
            </div>
      </div>
    </footer>
  );
}

export default FooterParticipant;